import * as React from "react"
import { useRef, useState, useEffect } from "react"
import { Layout } from "../../components/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { FeaturedOn } from "../../components/featuredOn/featuredOn"
import { AcuityModal } from "../AcuityModal/AcuityModal"
import { TextCopies } from "../../helpers/countryTextCopies"
import { HeaderMarks } from "../common/HeaderMarks"
import { returnLink } from "../../helpers/returnLink"
import HeaderImg from "../../images/thank-you/thankYouHeader.webp"
import CallImg from "../../images/thank-you/thankYouCall.webp"
import AssessmentImg from "../../images/thank-you/processAssessment.webp"
import WhatsAppImg from "../../images/thank-you/whatsappIcon.webp"
import HeaderRightSvg from "../../assets/header/headerRight.svg"
import CallWavy from "../../assets/thank-you/thankYouWavy.svg"
import { Reviews } from "../../components/Reviews/reviews"
import queryString from "query-string"
import DrEdwardsVideo from "../../videos/dr-edwards-video.mp4"

const ThankYouPageTD = ({ prefix, location }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Thank You",
    description:
      "Congratulations for taking the first step towards gaining the self confidence you deserve! We have helped thousands of men globally overcome this complex challenge with affordable and customised medical treatments.",
    url: `https://menshealthclinic.com/${prefix}/thank-you`,
  }
  // WEBPAGE SCHEMA END //

  const acuityLink = useRef({
    au: "https://menshealthclinic.as.me/thankyoupage?utm_source=thank_you&utm_medium=book_now",
    ca: "https://app.acuityscheduling.com/schedule.php?owner=24056947&calendarID=6108046",
    nz: "https://app.acuityscheduling.com/schedule.php?owner=21987254&appointmentType=31987889",
    uk: "https://app.squarespacescheduling.com/schedule.php?owner=26169664&appointmenttype=33136276",
  })

  const [acuityInformation, setAcuityInformation] = useState(null)
  const [queryId, setQueryId] = useState("")

  useEffect(() => {
    const cookie = document.cookie.match(
      new RegExp("userInformation" + "=([^;]+)")
    )
    if (cookie) {
      setAcuityInformation(JSON.parse(cookie[1]))
    }
  }, [])

  const queryParamsId = queryString.parse(location.search).queryId

  useEffect(() => {
    setQueryId(queryParamsId)
  }, [queryParamsId])

  return (
    <Layout
      headerLink={
        queryId === "3"
          ? "https://menshealthclinic.as.me/chronicpainwebsite"
          : acuityInformation
          ? acuityLink.current[prefix] +
            `&firstName=${acuityInformation.first_name}&lastName=${acuityInformation.last_name}&email=${acuityInformation.email}&phone=${acuityInformation.mobile}`
          : acuityLink.current[prefix]
      }
      headerMainButtonText="Book Now"
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "thankyoubottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title={
          prefix === "uk"
            ? "MHC Men's Health Clinic | Leaders in ED & PE Treatment"
            : "Men's Health Clinic | Leaders in ED & PE Treatment"
        }
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/thank-you/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/thank-you/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/thank-you/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/thank-you/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/thank-you/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/thank-you/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article>
        <header
          className="relative w-full bg-030654 max680:!bg-none max680:!pb-0"
          style={{
            backgroundImage: `linear-gradient(to top,rgba(3, 6, 84, 0.1),rgba(3, 6, 84, 1)),url(${HeaderImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="width__container h-[100%]">
            <div className="left-blue-semicircle hidden max680:block"></div>
            <div>
              <div className="home__header--article-wrapper relative h-full">
                <HeaderRightSvg className="absolute bottom-0 right-0 hidden max680:block" />
                <div className="relative flex flex-row items-center gap-x-50 max680:text-center max1000:flex-col">
                  <h1 className="header__image--title home__header--img-title 680:hidden max680:mb-20 max680:text-3xl">
                    Congratulations!
                  </h1>
                  <p className="mb-20 hidden text-F5F5F5 max680:block">
                    For taking the first step towards gaining the self
                    confidence you deserve!
                  </p>
                  <div className="flex w-full max-w-600 flex-col max1000:mb-20">
                    <div className="video h-full w-full overflow-hidden rounded-20">
                      <video
                        autoPlay
                        muted
                        controls
                        playsInline
                        height={370}
                        width={660}
                        className="rounded-lg"
                      >
                        <source src={DrEdwardsVideo} type="video/mp4" />
                      </video>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <p className="header__image--dash-text home__header--img-text">
                      RESTORING CONFIDENCE IN MEN
                    </p>
                    <h1 className="header__image--title home__header--img-title max680:hidden">
                      Congratulations!
                    </h1>
                    <p className="header__image--text home__header-image--text max680:hidden">
                      For taking the first step towards gaining the self
                      confidence you deserve! We have helped thousands of men
                      globally overcome this complex challenge with affordable
                      and customised medical treatments.
                    </p>

                    <p className="mb-40 hidden text-F5F5F5 max680:block">
                      We have helped thousands of men globally overcome this
                      complex challenge with affordable and customised medical
                      treatments.
                    </p>
                  </div>
                </div>
                <HeaderMarks
                  className="mt-100 flex justify-between text-F5F5F5 max680:hidden max950:mt-20"
                  firstText={`Licenced ${TextCopies.countryAsAdjective(
                    prefix
                  )} doctors.`}
                  secondText="150,000+ treated patients."
                  thirdText="Discreet shipping and care."
                />
              </div>
            </div>
          </div>
        </header>

        <div className="width__container my-55">
          <p className="mx-auto w-full max-w-1000 text-center">
            Having challenges related to your health is nothing to be ashamed
            of. Most men will never seek treatment and will suffer in silence.
            Gain control of your health and take the next step now.
          </p>
        </div>
      </article>

      <main>
        <section className="relative mb-100 mt-85 flex max680:hidden max900:mt-0 max900:flex-col">
          <div className="left-top__blue-circle relative h-[350px] w-full bg-030654 max900:h-400 max900:max-w-100%">
            <CallWavy className="absolute right-0 top-0" />
            <img
              className="thank-you__call-now-img absolute right-[-1%] top-[-10%] z-10 max-w-[400px] rounded-20 max900:top-[30%] max900:w-310"
              src={CallImg}
              alt="Phone call icon"
            />
          </div>
          <div className="flex h-[350px] w-full flex-col justify-center bg-E0E0E0 pl-80 max900:bg-F5F5F5 max900:pb-50 max900:pl-0">
            <div className="max900:mt-140 max900:text-center">
              <p className="blueText process__blueText">Connect</p>
              <h2 className="text-4xl font-bold leading-9 text-333333">
                Reach out to us!
              </h2>
            </div>
            <div className="flex max900:flex-col">
              <div className="mr-[5%] mt-45 flex max-w-500 items-center max600:max-w-240 max600:flex-wrap max600:justify-center max600:px-0 max900:mx-auto max900:mt-50">
                <a
                  href={TextCopies.whatsappApiLink(prefix)}
                  className="slim__btn mx-auto flex max-w-290 items-center justify-center rounded-3xl bg-030654 text-center font-medium text-F5F5F5  transition duration-300 hover:bg-[#00003D] max600:whitespace-normal max900:mx-auto"
                >
                  <img
                    className="mr-10 max-w-[24px]"
                    src={WhatsAppImg}
                    alt="WhatsApp chat icon"
                  />
                  <p className="text-15 font-medium leading-6 text-F5F5F5">
                    Chat Now
                  </p>
                </a>
              </div>
              <div className="mt-45 flex max-w-500 items-center max600:max-w-240 max600:flex-wrap max600:justify-center max600:px-0 max900:mx-auto max900:mt-50">
                <a
                  href={`tel:${TextCopies.countryPhone(prefix)}`}
                  className="slim__btn mx-auto flex max-w-290 items-center justify-center rounded-3xl bg-030654 text-center  font-medium text-F5F5F5 transition duration-300 hover:bg-[#00003D] max600:whitespace-normal max900:mx-auto"
                >
                  <img
                    className="mr-10 max-w-[24px]"
                    src={AssessmentImg}
                    alt="Man on the phone"
                  />
                  <p className="text-15 font-medium leading-6 text-F5F5F5">
                    Call Now
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-65 hidden max680:block">
          <div className="bg-E0E0E0pl-0 flex w-full flex-col justify-center">
            <div className="text-center">
              <p className="blueText process__blueText">Connect</p>
              <h2 className="section__title">Reach out to us!</h2>
            </div>
            <div className="flex flex-col">
              <div className="mt-45 flex items-center justify-center">
                <a
                  href={TextCopies.whatsappApiLink(prefix)}
                  className="slim__btn flex items-center justify-center rounded-3xl bg-030654 text-center font-medium text-F5F5F5"
                >
                  <img
                    className="mr-10 max-w-[24px]"
                    src={WhatsAppImg}
                    alt="WhatsApp chat icon"
                  />
                  <p className="text-15 font-medium leading-6 text-F5F5F5">
                    Chat Now
                  </p>
                </a>
              </div>
              <div className="mt-45 flex items-center justify-center">
                <a
                  href={`tel:${TextCopies.countryPhone(prefix)}`}
                  className="slim__btn flex items-center justify-center rounded-3xl bg-030654 text-center font-medium text-F5F5F5"
                >
                  <img
                    className="mr-10 max-w-[24px]"
                    src={AssessmentImg}
                    alt="Man on the phone"
                  />
                  <p className="text-15 font-medium leading-6 text-F5F5F5">
                    Call Now
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        {prefix === "au" ? <FeaturedOn className="mb-145" /> : null}
        {prefix === "au" ? (
          <Reviews
            className="mb-185 max680:mb-145"
            link={returnLink(
              "Website-Quiz",
              "Supplementary-Quiz",
              "chronicpainreviewsquiz",
              false,
              "au"
            )}
            prefix={prefix}
          />
        ) : null}
      </main>
    </Layout>
  )
}

export default ThankYouPageTD