import React from "react"
import ThankYouPageTD from "../../components/PageComponents/thank-you-td"

const ThankYouTDPage = ({location }) => {
  const prefix = "au"
  
  return (
    <ThankYouPageTD
      prefix={prefix} 
      location={location} 
      link="https://www.youtube.com/embed/5lfI4rsCBRg?si=scd2AEJv284du4bn" 
    />
  )
}

export default ThankYouTDPage
